import EmbedEditPage from "../Pages/SchemaBuilder/Pages/EmbedEdit";
import EmbedViewPage from "../Pages/SchemaBuilder/Pages/EmbedView";
import EmbedSearchPage from "../Pages/SchemaBuilder/Pages/EmbedSearch";

const EmbedRoutes = [
  { path: `${process.env.PUBLIC_URL}/search/:orgId/:schemaId`, Component: <EmbedSearchPage  /> },
  { path: `${process.env.PUBLIC_URL}/view/:orgId/:schemaId`, Component: <EmbedViewPage  /> },
  { path: `${process.env.PUBLIC_URL}/edit/:orgId/:schemaId`, Component: <EmbedEditPage  /> }
]

export default EmbedRoutes;