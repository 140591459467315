import SchemaBuilderContainer from '../../../Component/SchemaBuilder/SchemaBuilder';
import { useParams } from 'react-router-dom';


const SchemaBuilderPage = () => {
    const { editId } = useParams<{ editId: string }>();
    const schemaId = editId ? parseInt(editId, 10) : 0;

    return (
        <div className='page-body'>
            <SchemaBuilderContainer schemaId={schemaId} />
        </div>
    );
};

export default SchemaBuilderPage;
