import { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import { Model, Serializer } from 'survey-core';
import './css/index.css';
import './css/schemabuilder.css';
import { getSchema, updateSchema } from "../../utils/supabase";


const SchemaBuilderContainer = (props) => {
    const [schema, setSchema] = useState(null);
    const [creator, setCreator] = useState(null);
    const { schemaId } = props;
    const [error, setError] = useState(null);

    const [id, setId] = useState(schemaId);
    const arcId = localStorage.getItem('orgId') || '';

    useEffect(() => {
        const creator = new SurveyCreator({
            showLogicTab: false,
            isAutoSave: true,
            showState: true,
        });

        setCreator(creator);
        Serializer.removeProperty("survey", "logo");

        if (id && id > 0) {
            getSchema(id, arcId).then((response) => {
                if (response) {
                    setSchema(response);
                    const model = new Model();
                    model.fromJSON(response.schema);
                    creator.text = JSON.stringify(model.toJSON());
                    setCreator(creator);

                } else {
                    setError("Please return to the List page to select a schema.");
                    console.log("Schema not found");
                }
            }).catch((error) => {
                console.error("Error fetching schema:", error);
            });
        }

        creator.saveSurveyFunc = (saveNo, callback) => {
            updateSchema(creator.JSON, id, arcId);
            callback(saveNo, true);
        };
    }, [id]);

    if (!creator) {
        return renderLoading();
    }

    function renderLoading() {
        return <div>Loading...</div>;
    }

    return (
        <Container fluid>
            <Row>
                <Col sm="12">
                    <Card style={{ padding: '0', height: '2000px' }}>
                        <CardBody style={{ padding: '0' }}>
                            {error ? <div>{error}</div> :
                                <SurveyCreatorComponent creator={creator} />
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default SchemaBuilderContainer;
