import SchemaBuilderList from '../../../Component/SchemaBuilder/List'

const SchemaBuilderListPage = () => {
  return (
    <div className='page-body'>
      <SchemaBuilderList />
    </div>
  )
}

export default SchemaBuilderListPage