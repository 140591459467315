import SchemaBuilderPage from "../Pages/SchemaBuilder/Pages/SchemaBuilder";
import SchemaBuilderListPage from "../Pages/SchemaBuilder/Pages/List";
import SchemaBuilderAddPage from "../Pages/SchemaBuilder/Pages/Add";
import SchemaBuilderConfigurePage from "../Pages/SchemaBuilder/Pages/Configure";

const routes = [
  { path: `${process.env.PUBLIC_URL}/schemabuilder/designer`, Component: <SchemaBuilderPage /> },
  { path: `${process.env.PUBLIC_URL}/schemabuilder/list`, Component: <SchemaBuilderListPage /> },
  { path: `${process.env.PUBLIC_URL}/schemabuilder/designer/:editId`, Component: <SchemaBuilderPage /> },
  { path: `${process.env.PUBLIC_URL}/dashboard`, Component: <SchemaBuilderPage /> },
  { path: `${process.env.PUBLIC_URL}/schemabuilder/configure/:editId`, Component: <SchemaBuilderConfigurePage /> },
  { path: `${process.env.PUBLIC_URL}/schemabuilder/add`, Component: <SchemaBuilderAddPage  /> },
]

export default routes;