import { Provider } from "react-redux";
import RouterData from "./Routes";
import { ToastContainer } from "react-toastify";
import Store from "./ReduxToolkit/Store";
import { setLicenseKey } from "survey-core";
import { useState, createContext, useEffect } from 'react'
import { getSession } from '../src/utils/supabase';
import { createClient } from '@supabase/supabase-js';


export const UserContext = createContext(null);
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL ? process.env.REACT_APP_SUPABASE_URL : '';
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY ? process.env.REACT_APP_SUPABASE_KEY : '';
const supabase = createClient(supabaseUrl, supabaseKey);
const has_session = localStorage.getItem('sb-huioktgvbjnujbsjtlhs-auth-token');

const App = () => {
  setLicenseKey("Mzc5ZGM3YjQtMmIyNS00OTI1LWE2ODEtNGVhZjFjOWZlYmUwOzE9MjAyNC0xMC0wMg==");
  const [session, setSession] = useState(); // Define session state
  const [loading, setLoading] = useState(true)
  const [isValidOrg, setIsValidOrg] = useState(false)
  let subscription;


  useEffect(() => {
    // Function to handle changes in session
    const handleAuthChange = (event: any, newSession: any) => {
      setSession(newSession);
    };

    const s: any = getSession();
    setSession(s);

    async function fetchSession() {
      try {
        const userSession: any = await getSession();
        setSession(userSession);
      } catch (error) {
        console.error("Error fetching session:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchSession()

    subscription = supabase.auth.onAuthStateChange(handleAuthChange);

    if (has_session) {
      setIsValidOrg(true)
    }
  }, [has_session, isValidOrg]);

  return (
    <>
      <Provider store={Store} >
        <RouterData />
        <ToastContainer />
      </Provider>
    </>
  );
};

export default App;
