import { Outlet } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../ReduxToolkit/Hooks'
import Loader from './Loader/Loader'
import { setToggleSidebar } from '../ReduxToolkit/Reducers/LayoutSlice'
import { useEffect } from 'react'
import { setLayout } from '../ReduxToolkit/Reducers/ThemeCustomizerSlice'

const EmbedLayout = () => {
  return (
    <>
      <Loader />
      <div className={`page-wrapper`}>
        <div className="page-body-wrapper">
          <Outlet />
        </div>
      </div>
    </>
  )
}

export default EmbedLayout