import SchemaBuilderAdd from '../../../Component/SchemaBuilder/Add'

const SchemaBuilderAddPage = () => {
    const arcId = localStorage.getItem('orgId') || '';

  return (
    <div className='page-body'>
      <SchemaBuilderAdd arcId={arcId} />
    </div>
  )
}

export default SchemaBuilderAddPage