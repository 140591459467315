import { MenuItem } from "../../Types/Layout/SidebarType";


export const MenuList: MenuItem[] = [
  {
    title: "Tools",
    lanClass: "lan-1",
    Items: [
      {
        title: "SchemaBuilder",
        icon: "home",
        type: "sub",
        lanClass: "lan-3",
        children: [
          {
            title:"Schemas",
            path:`${process.env.PUBLIC_URL}/schemabuilder/list`,
            type:"link"
          },
          {
            title:"+ Add",
            path:`${process.env.PUBLIC_URL}/schemabuilder/add`,
            type:"link"
          },
         
        ]
      },
      
    ]
  }
];