import { useState, useEffect } from "react";
import { Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";

// supabase
import { addSchema, checkSlugExists } from '../../utils/supabase'
import { useNavigate } from 'react-router-dom';
import { H3, P } from "../../AbstractElements";
// Material UI imports

import {
    TextField,
    Button,
} from '@mui/material';
import { error } from "console";


export default function SchemaBuilderAdd(props: { arcId: string }) {

    const { arcId } = props;
    const navigate = useNavigate()

    const [schemaName, setSchemaName] = useState('')
    const [schemaDesc, setSchemaDesc] = useState('')
    const [schemaAlias, setSchemaAlias] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    // Section of states for Alias Field Validations.
    const [aliasDisabled, setAliasDisabled] = useState(true)
    const [aliasExists, setAliasExists] = useState(false)
    const [invalidAliasFormat, setInvalidAliasFormat] = useState(false)
    const [submitBtnDisabled, setSubmitButtonDisabled] = useState(false)


    async function checkAliasExists(alias: string) {
        let flag = await checkSlugExists(arcId, alias)
        if (flag) {
            setSubmitButtonDisabled(true)
            setAliasExists(true)
        } else {
            setSubmitButtonDisabled(false)
            setAliasExists(false)
        }
    }

    async function handleClick(e: any) {
        if (schemaName === '' || schemaDesc === '') {
            setErrorMessage('Please fill in name and description')
            return;
        }
        await checkAliasExists(schemaAlias)
        const response = await addSchema(schemaName, schemaDesc, schemaAlias, arcId, 'EMBEDDED');
        if (response && response.length > 0) {
            const id = response[0].id
            const url = `/schemabuilder/designer/${id}`
            navigate(url)
        }
        else {
            console.error('Error adding schema:', response);
        }

    }


    useEffect(() => {
        if (schemaName === '') {
            setSubmitButtonDisabled(true);
        } else {
            setSubmitButtonDisabled(false);
        }
    }, [schemaName]);


    return (

        <Container boxed>
            <Row>
                <Col sm="6">
                    <Card >
                        <CardBody >
                            <Form className="theme-form" onSubmit={(e) => handleClick(e)} >
                                <H3>Add Schema</H3>
                                <hr />
                                <FormGroup>
                                    <Label className="col-form-label">Schema Name</Label>
                                    <Input type="text"
                                        id="schemaName"
                                        value={schemaName}
                                        label="Name *"
                                        variant="outlined"
                                        reqired
                                        onChange={(e) => {
                                            const newSchemaName = e.target.value
                                            setSchemaName(newSchemaName)
                                            const alias: string = newSchemaName.replace(/\s+/g, '_').toLowerCase().replace(/_+$/, '')
                                            setSchemaAlias(alias)
                                            checkAliasExists(alias)
                                        }}
                                    />
                                </FormGroup>

                                <FormGroup className="mb-0 form-sub-title">
                                    <Label className="col-form-label">Description</Label>
                                    <TextField
                                        multiline
                                        rows={4}
                                        fullWidth
                                        id="description"

                                        variant="outlined"
                                        onChange={(e) => setSchemaDesc(e.target.value)}
                                    />
                                    <FormGroup className="mb-0 form-sub-title">
                                        {aliasExists ? (
                                            <P>Schema name already exists. Please pick a different name.</P>
                                        ) : null}

                                        {invalidAliasFormat ? (
                                            <P>Please make sure the alias does not contain uppercase letters or spaces.</P>
                                        ) : null
                                        }
                                    </FormGroup>
                                    <Button disabled={submitBtnDisabled} variant="outlined" onClick={handleClick}>
                                        Add Schema
                                    </Button>
                                </FormGroup>

                            </Form>

                            {errorMessage === '' ? null : <P>{errorMessage}</P>}

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>


    )

}

