import { useState, useEffect, useCallback } from "react";
import { getSchema } from '../../utils/supabase';
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { P } from '../../AbstractElements';
import { sendMessage } from "../../utils/helper/utilities";
import { Survey } from 'survey-react-ui';
import { Model } from 'survey-core';
import { getKey } from "../../utils/helper/utilities";

interface Schema {
    id: number;
    name: string;
    template: string;
    slug: string;
    [key: string]: any;
}

const EmbedSearch = ({ schemaId, orgId }: { schemaId: string; orgId: string }) => {
    const [schema, setSchema] = useState<Schema | null>(null);

    const surveyJson = schema?.schema ?? null;
    const survey = surveyJson ? new Model(surveyJson) : null;

    useEffect(() => {
        
        async function startUp() {
            const height = document.body.scrollHeight;
            await fetchSchema();
            sendMessage("ready", {
                height: `${height}px`,
                width: '700px'
            });
        }
        startUp();

        document.body.style.backgroundColor = 'transparent';
        const staticBodyElements = document.querySelectorAll('.sd-body') as NodeListOf<HTMLElement>;
        staticBodyElements.forEach(element => {
            element.style.padding = '0';
        });

        const sdTitles = document.querySelectorAll('.sd-title') as NodeListOf<HTMLElement>;
        sdTitles.forEach(element => {
            element.style.paddingTop = '15px';
        });
    }, [schemaId]);


    async function fetchSchema() {
        try {
            const s = await getSchema(schemaId, orgId);
            setSchema(s || null);
        } catch (error) {
            console.error('Error Fetching Schema:', error);
        }
    }

    const surveyComplete = useCallback((sender: any) => {
        const data = sender.data;
        const singleContent = data;

        const ansCustomEmbed = {
            id: getKey(),
            url: '/',
            config: {
                "content": { singleContent }
            }
        }
        sendMessage('data', ansCustomEmbed)
    }, []);

    if (survey) {
        survey.showCompletedPage = false;
        survey.onComplete.add(surveyComplete);
    }

    return (
        <Container fluid style={{ padding: 0, margin: 0 }}>
            <Row>
                <Col sm="12">
                    <Card style={{paddingBottom:'0px', marginBottom:'0px'}}>
                        <CardBody>
                            {survey ? (
                                <Survey model={survey} />
                            ) : (
                                <P>Loading Schema...</P>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default EmbedSearch;
