import { useParams } from 'react-router-dom';
import EmbedEdit from '../../../Component/SchemaBuilder/EmbedEdit'

const EmbedEditPage = () => {
    const { orgId, schemaId } = useParams<{ orgId: string; schemaId: string }>();
  return (
    <div className='page-body' style={{margin:'0', padding:'0'}}>
      <EmbedEdit schemaId={schemaId?schemaId:''} orgId={orgId?orgId:''} />
    </div>
  )
}

export default EmbedEditPage