import { useParams } from 'react-router-dom';
import SchemaBuilderConfigure from '../../../Component/SchemaBuilder/Configure'

const SchemaBuilderConfigurePage = () => {
    const { editId } = useParams<{ editId: string }>();
    const schemaId = editId ? editId :  '';
        
  return (
    <div className='page-body'>
      <SchemaBuilderConfigure schemaId={schemaId}  />
    </div>
  )
}

export default SchemaBuilderConfigurePage