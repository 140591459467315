import { useParams } from 'react-router-dom';
import EmbedView from '../../../Component/SchemaBuilder/EmbedView';

const EmbedViewPage = () => {
    const { orgId, schemaId } = useParams<{ orgId: string; schemaId: string }>();

    return (
        <div >
            <EmbedView schemaId={schemaId?schemaId:''} orgId={orgId?orgId:''} />
        </div>
    );
}

export default EmbedViewPage;
