
import { Navigate, Outlet, useLocation } from "react-router-dom";
import {useEffect} from 'react';

const EmbedRoute = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const authorized = queryParams.get('k');

  return authorized ? (
    <Outlet />
    ) : (
    <p>You do not have permission to access this page.</p>
  );
};

export default EmbedRoute;
