
import { supabaseClient } from './supabaseclient';
import { toast } from 'react-toastify';


export const checkSlugExists = async (arcOrgId, slug) => {
  const tableName = arcOrgId + "_schema"; 
  return supabaseClient
    .from(tableName)
    .select('slug', { count: 'exact', head: true })
    .eq('slug', slug)
    .then(({ data, error, count }) => {
      if (error) {
        console.error('Error fetching data:', error);
        return false;
      } else {
        return count > 0;
      }
    });
}

export const getUserProfile = async (arcOrgId) => {
  const tableName = arcOrgId + "_schema"; 
  // const { data: { users }, error } = await supabaseClient.auth.admin.listUsers()
  const { data, error } = await supabaseClient
    .from('user_organization')
    .select(
      `id,email,user_id, organization(*)`,
      { foreignKey: 'user_id' }
    )

  console.log('data:', data)
  if (!error) {
    return data;
  }
  else {
    console.log('getUsers error: ', JSON.stringify(error))
    toast.error('Error Fetching Users', {
      autoClose: 5000, 
      position: 'top-right', 
    });
    return null;
  }
}

export const getAllOrganization = async (arcOrgId) => {
  const tableName = "organization"; 
  const { data, error } = await supabaseClient
    .from(tableName)
    .select()
  if (!error) {
    return data;
  }
  else {
    console.log('getAllOrganization error: ', JSON.stringify(error))
    toast.error('Error Fetching Organization', {
      autoClose: 5000, 
      position: 'top-right', 
    });
    return null;
  }
}

export const getSchemas = async (arcOrgId) => {
  const tableName = arcOrgId + "_schema"; 
  console.log('tableName:', tableName)
  const { data, error } = await supabaseClient
    .from(tableName)
    .select()

  if (!error) {
    return data;
  }
  else {
    console.log('getSchemas error: ', JSON.stringify(error))
    return null;
  }
}

export const getSingleContent = async (id, arcOrgId) => {
  const tableName = arcOrgId + "_content";
  const fields = 'id,content,schemaId,' + arcOrgId + '_schema(*)'
  const { data, error } = await supabaseClient
    .from(tableName)
    .select(fields)
    .eq('id', id)

  if (!error) {
    return data;
  }

  else {
    toast.error('Error Fetching Schema', {
      autoClose: 5000, 
      position: 'top-right', 
    });

    return null;
  }
}


export const getSingleContentNoSchema = async (id, arcOrgId) => {
  const tableName = arcOrgId + "_content";
  const fields = 'id,content'
  const { data, error } = await supabaseClient
    .from(tableName)
    .select(fields)
    .eq('id', id)

  if (!error) {
    return data;
  }
  else {
    toast.error('Error Fetching Schema', {
      autoClose: 5000, 
      position: 'top-right', 
    });

    return null;
  }
}

export const getAllContent = async (arcOrgId) => {
  const tableName = arcOrgId + '_content';
  const { data, error } = await supabaseClient
    .from(tableName)
    .select()

  if (!error) {
    return data;
  }
  else {
    console.log('error fetching content: ', JSON.stringify(error))
    toast.error('Error Fetching Content', {
      autoClose: 5000, 
      position: 'top-right', 
    });
    return null;
  } 
}

export async function fetchSchemaIdCounts(arcId) {
  const tableName = arcId + '_content';
  const { data, error } = await supabaseClient
    .rpc('get_schemaid_counts', { dynamic_table_name: tableName });

  if (error) {
    console.error('Error fetching schema ID counts:', error);
    return null;
  }
  return data;
}

export const getContent = async (id, arcOrgId) => {
  const tableName = arcOrgId + '_content';
  const { data, error } = await supabaseClient
  .from(tableName)
  .select('*')
  .eq('schemaId', id)
  .limit(30000)
  
  if (!error) {
    return data;
  }
  else {
    console.log('error retrieving schema: ', JSON.stringify(error))
    toast.error('Error Fetching Content', {
      autoClose: 5000,
      position: 'top-right',
    });
    return null;
  }
}

export const getContentCount = async (schemaId, arcOrgId) => {
  const tableName = arcOrgId + '_content';
  const { count, error } = await supabaseClient
    .from(tableName)
    .select('*', { count: 'exact' })
    .eq('schemaId', schemaId);

  if (!error) {
    return count;
  } else {
    console.log('Error retrieving schema: ', JSON.stringify(error));
    toast.error('Error Fetching Content Count', {
      autoClose: 5000,
      position: 'top-right',
    });
    return null;
  }
};


export const setDropDownChangeValue = async (schemaId, newValue, oldValue, arcOrgId) => {
  const tableName = arcOrgId + '_content';
  const contents = await getContent(schemaId, arcOrgId);
  console.log('contents by schema: ', contents);

  if (contents) {
    let updated = false;
    for (let index in contents) {
      let item = contents[index];
      // console.log('item:', item);

      // Scan inside the keys of item.content
      for (let key in item.content) {
        if (item.content[key] === oldValue) {
          item.content[key] = newValue;
          updated = true;

          if (updated) {
            const { error: updateError } = await supabaseClient
              .from(tableName)
              .update({ content: item.content })
              .match({ id: item.id });

            if (updateError) {
              console.error('Error updating content:', updateError);
              throw updateError;
            }
            updated = false;
          }
        }
      }
    }
  }
};


export const getSchema = async (id, arcOrgId) => {
  const tableName = arcOrgId + '_schema';
  const { data, error } = await supabaseClient
    .from(tableName)
    .select()
    .eq('id', id)

  if (!error) {
    if (data.length > 0) {
      return data[0];
    }
    return null;
  }
  else {
    console.log('error retrieving schema: ', JSON.stringify(error));
    return null;
  }
}


export const addSchema = async (schemaName, schemaDesc, slug, arcOrgId, sourceType) => {
  const tableName = arcOrgId + '_schema';

  // assin source type
  let source = "ARC_EMBEDDED"
  if(sourceType){
    source = "DATABASE_ONLY"
  }
  
  var schemaObject = { "title": schemaName, "elements": "[]", "description": schemaDesc };

  const { data, error } = await supabaseClient
    .from(tableName)
    .insert({ schema: schemaObject, slug: slug, source: source, name: schemaName})
    .select()

  if (!error) {
    return data;
  }
  else {
    console.log('error adding schema ', JSON.stringify(error));
    return null;
  }
}

export const updateSchema = async (s, i, arcOrgId) => {
  const tableName = arcOrgId + '_schema';
  const { data, error } = await supabaseClient
    .from(tableName)
    .upsert({ id: i, schema: s })
    .select()

  if (!error) {
    return data;
  }
  else {
    console.log('error: ', JSON.stringify(error))
    toast.error('Error Updating Schema', {
      autoClose: 5000,
      position: 'top-right',
    });
    return null;
  }
}

export const updateSchemaSource = async (id, arcOrgId, source) => {
  const tableName = arcOrgId + '_schema';


  const { data, error } = await supabaseClient
    .from(tableName)
    .upsert({ id: id, source: source })
    .select()

  if (!error) {
    return data;
  }
  else {
    console.log('error: ', JSON.stringify(error))
    toast.error('Error Updating Schema Source', {
      autoClose: 5000,
      position: 'top-right',
    });
    return null;
  }
}


export const updateSchemaTemplate = async (t, i, arcOrgId) => {
  const tableName = arcOrgId + '_schema';

  const { data, error } = await supabaseClient
    .from(tableName)
    .upsert({ id: i, template: t })
    .select()

  if (!error) {
    return data;
  }
  else {
    console.log('error: ', JSON.stringify(error))
    toast.error('Error Updating Schema Template', {
      autoClose: 5000,
      position: 'top-right',
    });
    return null;
  }
}

export const deleteSchema = async (schemaId, arcOrgId) => {
  const tableName = arcOrgId + '_schema';

  const { data, error } = await supabaseClient
    .from(tableName)
    .delete()
    .eq('id', schemaId);

  if (!error) {
    return data;
  } else {
    console.log('Error deleting schema ', JSON.stringify(error));
    toast.error('Error Deleting Schema', {
      autoClose: 5000,
      position: 'top-right',
    });
    return null;
  }
};

export const deleteContent = async (id, arcOrgId) => {
  const tableName = arcOrgId + '_content';
  const { data, error } = await supabaseClient
    .from(tableName)
    .delete()
    .eq('id', id)

  if (!error) {
    return data;
  }
  else {
    console.log('error deleting content: ', JSON.stringify(error))
    toast.error('Error deleting Content', {
      autoClose: 5000,
      position: 'top-right',
    });
    return null;
  }
}

export const updateContent = async (c, i, arcOrgId, schemaId) => {

  const tableName = arcOrgId + "_content";

 if (i && i > 0) {
  const { data, error } = await supabaseClient
    .from(tableName)
    .update({ content: c })
    .eq('id', i)
    .select()
    if (!error) {
      return data;
    }
    else {
      console.log('error: ', JSON.stringify(error))
      toast.error('Error Updating Content', {
        autoClose: 5000,
        position: 'top-right',
      });
      return null;
    }

  } else
  {
    const { data, error } = await supabaseClient
    .from(tableName)
    .insert({ content: c , schemaId: schemaId})
    .select()
    if (!error) {
      return data;
    }
    else {
      console.log('error: ', JSON.stringify(error))
      toast.error('Error Updating Content', {
        autoClose: 5000,
        position: 'top-right',
      });
      return null;
    }

  }
  return null;
}

export const isLoggedIn = async () => {
  supabaseClient.auth.getSession().then(({ data: { session } }) => {
    //console.log('session: ', JSON.stringify(session));
    return true;
  });

  return false;
}

export const createUser = async (email) => {
  try{
    console.log('email:',supabaseClient.auth.admin)
    const { data, error } = await supabaseClient.auth.admin.inviteUserByEmail(email)
    return data
  }catch(error){
    console.log("ERR:", error);
    toast.error('Error setting user (supabase)', {
      autoClose: 5000,
      position: 'top-right',
    });
  }
}

export const createUserProfileByUser = async (user, org_id) => {
  const tableName = 'user_organization';

  const params = {
    'email': user.user.email,
    'user_id': user.user.id,
    'org_id': org_id
  }

  const { data, error } = await supabaseClient
    .from(tableName)
    .insert({user_id: params.user_id, email:params.email, organizationId: params.org_id})
  if (!error) {
    return data;
  }
  else {
    console.log('error adding logs ', JSON.stringify(error));
    toast.error('Error Adding logs', {
      autoClose: 5000,
      position: 'top-right',
    });
    return null;
  }
}

export const getUser = async () => {
  const res = await supabaseClient.auth.getUser();

  if (res.data.user ==null || typeof res?.data?.user === 'undefined') {
    return null;
  }
  else {
    return res.data.user 
  }
}

export const getUserOrganization = async (email) => {

  const { data, error } = await supabaseClient
    .from('user_organization')
    .select('id,email,organizationId,organization(*)')
    .eq('email', email)

  if (!error) {
    //console.log('org info: ', JSON.stringify(data))
    return data;
  }
  else {
    console.log('error retrieving user organization: ', JSON.stringify(error));
    return null;
  }
}

export const getValidUserOrganization = async (email, orgId) => {

  const { data, error } = await supabaseClient
    .from('user_organization')
    .select('id,email,organizationId,organization(*)')
    .eq('email', email)

  if (!error) {
    const org = data.find(x => x.organization?.arc_id == orgId)
    return org;
  }
  else {
    console.log('error retrieving user organization: ', JSON.stringify(error));
    return null;
  }
}

export const getUserRole = async (org_id) => {
  console.log('id->',org_id)
  const { data, error } = await supabaseClient
  .from('usersinrole')
  .select('id,user_organization,role(*)')
  .eq('user_organization', org_id)

  if (!error) {
    //console.log('role info: ', JSON.stringify(data))
    return data;
  }
  else {
    console.log('error retrieving user role: ', JSON.stringify(error));
    return null;
  }
}

export const addLogDetails = async (logData) => {
  const tableName = 'log';
  // var schemaObject = { "title": schemaName, "elements": "[]", "description": schemaDesc  };

  const { data, error } = await supabaseClient
    .from(tableName)
    .insert(logData)
  if (!error) {
    return data;
  }
  else {
    console.log('error adding logs ', JSON.stringify(error));
    toast.error('Error Adding logs', {
      autoClose: 5000,
      position: 'top-right',
    });
    return null;
  }
}

export const getLogDetails = async (arcID, schemaId=null) => {
  const tableName = 'log'
  console.log("SID:",schemaId)
  try{
    if(!schemaId){
      const {data, error } = await  supabaseClient
      .from(tableName)
      .select('id,created_by(*),action,arc_id,schema_id, content_id,prev_content,current_content,created_at')
      .eq('arc_id', arcID)
      .order('created_at', { ascending: false }); 
      return data
    }else{
      const {data, error } = await  supabaseClient
      .from(tableName)
      .select('id,created_by(*),action,arc_id,schema_id, content_id,prev_content,current_content,created_at')
      .eq('arc_id', arcID)
      .eq('schema_id', schemaId)
      .order('created_at', { ascending: false }); 
      return data
    }
  } catch {
    toast.error('Error getting logs (supabase)', {
      autoClose: 5000,
      position: 'top-right',
    });
  }
  return null
}

export const getSession = async () => {
  supabaseClient.auth.getSession().then(({ data: { session } }) => {
    return session;
  });
}


export const logout = async () => {
    supabaseClient.auth.signOut();
    localStorage.removeItem('role');
    localStorage.removeItem('org_id');
    localStorage.removeItem('session');
    localStorage.removeItem('session_arc_id');
    return await getUser();
}
