import { useState, useEffect, useCallback } from "react";
import { getANSData, sendMessage } from "../../utils/helper/utilities";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { H3, P } from '../../AbstractElements';
import { getSchema } from "../../utils/supabase";
import RenderJson from "./RenderJson";
import { Survey } from 'survey-react-ui';
import { Model } from 'survey-core';

interface EmbedViewProps {
    schemaId: string;
    orgId: string;
}

const EmbedView = ({ schemaId, orgId }: EmbedViewProps) => {
    const [content, setContent] = useState<string>('');
    const [schema, setSchema] = useState<any>(null);

    console.log('SchemaId:', schemaId);
    console.log('OrgId:', orgId);

    const surveyJson = schema?.schema ?? null;
    const survey = surveyJson ? new Model(surveyJson) : null;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const ansData = getANSData();
                if (ansData) {
                    const parsedData = JSON.parse(ansData);
                    const contentData = parsedData.config.content.singleContent;
                    setContent(contentData);
                }
            } catch (error) {
                console.error('Error Fetching Content:', error);
            }
        };

        const fetchSchema = async () => {
            try {
                const fetchedSchema = await getSchema(schemaId, orgId);
                setSchema(fetchedSchema);
            } catch (error) {
                console.error('Error Fetching Schema:', error);
            }
        };
        sendMessage("ready", {
            height:'300px',
            width: '750px',
            overflowY: 'scroll',

        });
        fetchData();
        fetchSchema();
    }, [schemaId, orgId]);

    
    
    const surveyComplete = useCallback((sender: any) => {
        const data = sender.data;
        console.log('Survey Data:', data);
    }, []);

    if (survey) {
        survey.showCompletedPage = false;
        survey.onComplete.add(surveyComplete);
        survey.data = content;
        console.log('Survey Data:', content);
    }

    return (
        <Container fluid style={{marginTop: '15px'}}>
            <Row>
                <Col sm="12">
                    <Card>
                        <CardBody>
                            <H3>{schema?.name}</H3>
                            <hr />
                            {content ? (
                                <table className="table table-bordered">
                                <RenderJson json={content} />
                                </table>
                            ) : (
                                <P>Loading Schema Content...</P>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default EmbedView;
