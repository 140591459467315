import { useParams } from 'react-router-dom';
import EmbedSearch from '../../../Component/SchemaBuilder/EmbedSearch'

const EmbedSearchPage = () => {
    const { orgId, schemaId } = useParams<{ orgId: string; schemaId: string }>();
    
  return (
    <>
    <div className='page-body' style={{margin:'0', padding:'0'}}>
      <EmbedSearch schemaId={schemaId?schemaId:''} orgId={orgId?orgId:''} />
    </div>
    </>
  )
}

export default EmbedSearchPage