import { Route, Routes } from "react-router-dom";
import EmbedLayout from "../Layout/EmbedLayout";
import EmbedRoutes from "./EmbedRoutes";

const LayoutRoutes = () => {
  return (
    <Routes>
      {EmbedRoutes.map(({ path, Component }, i) => (
        <Route element={<EmbedLayout />} key={i}>
          <Route path={path} element={Component}/>
        </Route>
      ))}
    </Routes>
  );
};

export default LayoutRoutes;
