import { useState, useEffect, FormEvent } from "react";
import { getSchema } from '../../utils/supabase';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { H3 } from '../../AbstractElements';
import { Table } from "reactstrap";

interface Schema {
    id: number;
    name: string;
    template: string;
    slug: string;
    [key: string]: any;
}

const SchemaBuilderConfigure = (props: { schemaId: string }) => {
    const { schemaId } = props;
    const [embedName, setEmbedName] = useState<string>('');
    const [schema, setSchema] = useState<Schema | null>(null);
    const arcId = localStorage.getItem('orgId') || '';
    const baseUrl = 'https://' + window.location.hostname || '';
    const [iFrameHeight, setIFrameHeight] = useState<string>('');
    const [iFrameWidth, setIFrameWidth] = useState<string>('');
    const [pbCode, setPbCode] = useState<string>('');
    const [embedCode, setEmbedCode] = useState<string>('');



    const fetchPBCode = async (): Promise<void> => {
        try {
            const pbCode = await fetch('/assets/codeGeneration/pageBuilderTemplate.txt').then((response) => response.text());
            setPbCode(pbCode);
        } catch (error) {
            setPbCode('Error generating PB code:' + error);
        }
    }

    const fetchEmbedCode = async (): Promise<void> => {
        try {
            const pbCode = await fetch('/assets/codeGeneration/EmbedTemplate.txt').then((response) => response.text());
            setEmbedCode(pbCode);
        } catch (error) {
            setEmbedCode('Error generating Embed code:' + error);
        }
    }

    const handleEmbedCodeGenerator = async () => {
        try {
            //const embedCode = await generateEmbedCode(schemaId, arcId);
            setEmbedCode(embedCode);
        } catch (error) {
            setEmbedCode('Error generating Embed code:' + error);
        }
    }

    const handlePBGenerator = async () => {
        try {
            //const pbCode = await generatePBCode(schemaId, arcId);
            setPbCode(pbCode);
        } catch (error) {
            setPbCode('Error generating PB code:' + error);
        }
    }

    const handlePBPackageGenerator = async () => {
        try {
            //const pbCode = await generatePBCode(schemaId, arcId);
            setPbCode(pbCode);
        } catch (error) {
            setPbCode('Error generating PB code:' + error);
        }
    }

    const handleClick = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        console.log('Form submitted');
    };

    const fetchSchema = async (): Promise<void> => {
        try {
            const s = await getSchema(schemaId, arcId);
            console.log('Schema:', s);
            setSchema(s);
        } catch (error) {
            console.error('Error fetching schema:', error);
        }
    };

    useEffect(() => {
        fetchSchema();
        fetchPBCode();
        fetchEmbedCode();
    }, [schemaId]);

    return (
        <Container fluid>
            <Row>
                <Col sm="6">
                    <Card>
                        <CardBody>
                            <Form className="theme-form" onSubmit={handleClick}>
                                <H3>{schema ? schema.name : ''} | Composer Settings</H3>
                                <hr />

                                <div className="table">

                                    <Table responsive className="table-primary">

                                        <tbody>
                                            <tr>
                                                <td>Search</td>
                                                <td>{baseUrl}/embed/search/{arcId}/{schemaId}</td>
                                            </tr>
                                            <tr>
                                                <td>Edit</td>
                                                <td>{baseUrl}/embed/edit/{arcId}/{schemaId}</td>
                                            </tr>
                                            <tr>
                                                <td>View</td>
                                                <td>{baseUrl}/embed/view/{arcId}/{schemaId}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="6">
                    <Card>
                        <CardBody>
                            <Form className="theme-form" onSubmit={handleClick}>
                                <H3>{schema ? schema.name : ''} | Embed Settings</H3>
                                <hr />

                                <FormGroup>
                                    <Label className="col-form-label">iFrame Height</Label>
                                    <Input
                                        type="text"
                                        id="iFrameHeight"
                                        value={iFrameHeight}
                                        required
                                        onChange={(e) => setIFrameHeight(e.target.value)}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label className="col-form-label">iFrame Width</Label>
                                    <Input
                                        type="text"
                                        id="iFrameWidth"
                                        value={iFrameWidth}
                                        required
                                        onChange={(e) => setIFrameWidth(e.target.value)}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Button type="submit" color="primary">
                                        Ok
                                    </Button>
                                </FormGroup>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col sm="12">
                    <CardBody>
                        
                            <H3>{schema ? schema.name : ''} | PageBuilder Package Generator</H3>
                            <p>Steps to create your own power up to include in your bundle</p>
                            <ol>
                                <li>In your bundle, install @arcxp/shared-powerup-composer-utils and survey-creator-react
                                    <ul>
                                        <li>npm install @arcxp/shared-powerup-composer-utils</li>
                                        <li>npm install survey-creator-react</li>
                                    </ul>
                                </li>
                                <li>Download the files created by clicking the Generate PageBuilder Package below</li>
                            </ol>
                            
                            <hr />
                            <label>Folder Name</label>
                            <Input type="text" value={embedName} />
                            <Button color="primary" className="mr-2" onClick={handlePBPackageGenerator}>
                                Generate PageBuilder Package
                            </Button>
                            <pre>{pbCode}</pre>


                        </CardBody>
                </Col>
            </Row>

            <Row>
                <Col sm="12">
                    <CardBody>
                        
                            <H3>{schema ? schema.name : ''} | PageBuilder Code Generation</H3>
                            <hr />
                            <Button color="primary" className="mr-2" onClick={handlePBGenerator}>
                                Generate PageBuilder block
                            </Button>
                            <pre>{pbCode}</pre>


                        </CardBody>
                </Col>
            </Row>

            <Row>
                <Col sm="12">
                    <CardBody>
                        
                            <H3>{schema ? schema.name : ''} | Custom Embed Code Generation</H3>
                            <hr />
                            <Button color="primary" className="mr-2" onClick={handleEmbedCodeGenerator}>
                                Generate Custom Embed
                            </Button>
                            <pre>{embedCode}</pre>


                        </CardBody>
                </Col>
            </Row>
        </Container>
    );
};

export default SchemaBuilderConfigure;
