import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { DataGrid } from '@mui/x-data-grid';
import { useState, useEffect } from "react";
import { deleteSchema, getSchemas } from '../../utils/supabase';
import { safeJSONParse } from '../../utils/helper/utilities';
import { Link, useNavigate } from "react-router-dom";

const SchemaBuilderListContainer = () => {
    const [schemaList, setSchemaList] = useState<Schema[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const arcId = localStorage.getItem('orgId') || '';

    async function fetchSchemas(): Promise<void> {
        try {
            const list: Schema[] = await getSchemaList(arcId);
            setSchemaList(list);
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    }

    async function getSchemaList(source: string): Promise<Schema[]> {
        const response = await getSchemas(source);
        return response ? response : [];
    }

    useEffect(() => {
        fetchSchemas();
    }, []);

    // Define the Schema type based on your actual schema structure
    interface Schema {
        id: number;
        schema: {

        };
        name: string;
        template: string;
        slug: string;
    }

    const columns = [
        { field: 'id', headerName: 'Id', width: 200, sortable: true },
        {
            field: 'name',
            headerName: 'Name',
            width: 350,
            sortable: true,
        },
        { field: 'slug', headerName: 'Slug', width: 200, sortable: true },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            sortable: false,
            renderCell: (params: any) => (
                <div>
                    <ul id="ts-1" className="action simple-list flex-row list-group">
                        <li style={{marginRight:'25px'}} className="edit list-group-item"> <i className="icon-pencil-alt" onClick={() => handleEdit(params.row.id)}></i></li>
                        <li style={{marginRight:'25px'}} className="delete list-group-item"> <i className="icon-trash" onClick={() => handleDelete(params.row.id)}></i></li>
                        <li className="configure list-group-item"> <i className="icon-briefcase" onClick={() => handleConfigure(params.row.id)}></i></li>
                    </ul>
                </div>
            )
        }
    ];

    const handleEdit = (id: number) => {
        navigate(`${process.env.PUBLIC_URL}/schemabuilder/designer/${id}`)
    };

    const handleConfigure = (id: number) => {
        navigate(`${process.env.PUBLIC_URL}/schemabuilder/configure/${id}`)
    };

    const handleDelete = (id: number) => {
        console.log(`Delete schema with id: ${id}`);
        deleteSchema(id, arcId)
        setSchemaList(prevList => prevList.filter(schema => schema.id !== id));
    };


    return (
        <Container fluid>
            <Row>
                <Col sm="12">
                    <Card style={{ padding: '0' }}>
                        <CardBody style={{ padding: '0' }}>
                            <DataGrid
                                sx={{
                                    minHeight: '500px',
                                }}
                                rows={schemaList}
                                columns={columns}
                                loading={loading}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 25,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5]}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default SchemaBuilderListContainer;
